import React from "react";
import useModal from "hooks/useModal";
import { Editor } from "@tinymce/tinymce-react";
import styled from "styled-components";
const StyledModal = styled.div`
  & .mt-0 {
    margin-top: 0;
  }
`;
const PolicyModal = ({ type, callback, data }) => {
  const { closeModal } = useModal();

  // console.log(data)
  return (
    <div className="modal show">
      <div className="modal-dim"></div>
      <div className="modal-con terms">
        <button
          type="button"
          className="btn btn-close"
          onClick={() => closeModal()}
        ></button>
        {type === "service" && (
          <>
            <div className="modal-tit">서비스 이용약관</div>
            <div className="terms-con">
              <div className="edit-box web-basic mt-0">
                <Editor
                  disabled={true}
                  toolbar={false}
                  // menubar={false}
                  inline={true}
                  initialValue={data}
                  init={{
                    height: 500,
                    plugins: [
                      "advlist", "autolink", "lists", "link", "image", "charmap", "preview", "anchor",
                      "searchreplace", "visualblocks", "code" ,"fullscreen",
                      "insertdatetime", "media", "table", "code", "help", "wordcount",
                    ],
                    // toolbar: 'undo redo | blocks fontfamily fontsize | bold italic underline strikethrough | link image media table | align lineheight | numlist bullist indent outdent | emoticons charmap | removeformat',
                    automatic_uploads: true,
                    file_browser_callback_types: "image",
                    file_picker_callback: function (callback, value, meta) {
                      if (meta.filetype === "image") {
                        let input = document.getElementById("my-file");
                        if (!input) return;
                        input.click();
                        input.onchange = function () {
                          let file = input?.files[0];
                          let reader = new FileReader();
                          reader.onload = function (e) {
                            // console.log("name", (e.target).result);
                            callback(e.target.result, {
                              alt: file.name,
                            });
                          };
                          reader.readAsDataURL(file);
                        };
                      }
                    },
                  }}
                  // apiKey={process.env.REACT_APP_TINYMCE_API_KET}
                  // cloudChannel="5-testing"
                />
              </div>
            </div>
          </>
        )}
        {type === "use" && (
          <>
            <div className="modal-tit">개인정보 수집 및 이용 동의</div>
            <div className="terms-con">
              <div className="edit-box web-basic mt-0">
                <Editor
                  disabled={true}
                  toolbar={false}
                  // menubar={false}
                  inline={true}
                  initialValue={data}
                  init={{
                    height: 500,
                    plugins: [
                      "advlist", "autolink", "lists", "link", "image", "charmap", "preview", "anchor",
                      "searchreplace", "visualblocks", "code" ,"fullscreen",
                      "insertdatetime", "media", "table", "code", "help", "wordcount",                    ],
                    // toolbar: 'undo redo | blocks fontfamily fontsize | bold italic underline strikethrough | link image media table | align lineheight | numlist bullist indent outdent | emoticons charmap | removeformat',
                    automatic_uploads: true,
                    file_browser_callback_types: "image",
                    file_picker_callback: function (callback, value, meta) {
                      if (meta.filetype === "image") {
                        let input = document.getElementById("my-file");
                        if (!input) return;
                        input.click();
                        input.onchange = function () {
                          let file = input?.files[0];
                          let reader = new FileReader();
                          reader.onload = function (e) {
                            // console.log("name", (e.target).result);
                            callback(e.target.result, {
                              alt: file.name,
                            });
                          };
                          reader.readAsDataURL(file);
                        };
                      }
                    },
                  }}
                  // apiKey={process.env.REACT_APP_TINYMCE_API_KET}
                  // cloudChannel="5-testing"
                />
              </div>
            </div>
          </>
        )}
        {type === "provision" && (
          <>
            <div className="modal-tit">개인정보 제3자에게 제공 동의</div>
            <div className="terms-con">
              <strong>
                프로젝트 매칭을 위해 아래와 같이 개인정보를 제3자에게
                제공합니다.
                <br />
                동의를 거부할 권리가 있으며, 동의 거부 시 AI프로젝트가 매칭
                되더라도 계약이 불가합니다.
              </strong>
              <div className="terms-table type2 m-none">
                <div className="tr">
                  <div className="th">제공받는 자</div>
                  <div className="th">제공 목적</div>
                  <div className="th">제공하는 개인정보 항목</div>
                  <div className="th">보유 및 이용 기간</div>
                </div>
                <div className="tr">
                  <div className="td">프로젝트 의뢰자 또는 지원자</div>
                  <div className="td">프로젝트 매칭 및 계약체결</div>
                  <div className="td">
                    [필수] 이름, 이메일, 연락처, 경력, 학력
                  </div>
                  <div className="td">AI프로젝트 매칭 및 계약 체결시까지</div>
                </div>
              </div>
              <div className="terms-table type2 m-block">
                <div className="tr">
                  <div className="th">제공받는 자</div>
                  <div className="td">프로젝트 의뢰자 또는 지원자</div>
                  <div className="th">제공 목적</div>
                  <div className="td">프로젝트 매칭 및 계약체결</div>
                  <div className="th">제공하는 개인정보 항목</div>
                  <div className="td">
                    [필수] 이름, 이메일, 연락처, 경력, 학력
                  </div>
                  <div className="th">보유 및 이용 기간</div>
                  <div className="td">AI프로젝트 매칭 및 계약 체결시까지</div>
                </div>
              </div>
            </div>
          </>
        )}
        {type === "marketing" && (
          <>
            <div className="modal-tit">
              마케팅 이용 및 광고성 정보 수신 동의
            </div>
            <div className="terms-con">
              <div className="edit-box web-basic mt-0">
                <Editor
                  disabled={true}
                  toolbar={false}
                  // menubar={false}
                  inline={true}
                  initialValue={data}
                  init={{
                    height: 500,
                    plugins: [
                      "advlist", "autolink", "lists", "link", "image", "charmap", "preview", "anchor",
                      "searchreplace", "visualblocks", "code" ,"fullscreen",
                      "insertdatetime", "media", "table", "code", "help", "wordcount",
                    ],
                    // toolbar: 'undo redo | blocks fontfamily fontsize | bold italic underline strikethrough | link image media table | align lineheight | numlist bullist indent outdent | emoticons charmap | removeformat',
                    automatic_uploads: true,
                    file_browser_callback_types: "image",
                    file_picker_callback: function (callback, value, meta) {
                      if (meta.filetype === "image") {
                        let input = document.getElementById("my-file");
                        if (!input) return;
                        input.click();
                        input.onchange = function () {
                          let file = input?.files[0];
                          let reader = new FileReader();
                          reader.onload = function (e) {
                            // console.log("name", (e.target).result);
                            callback(e.target.result, {
                              alt: file.name,
                            });
                          };
                          reader.readAsDataURL(file);
                        };
                      }
                    },
                  }}
                  // apiKey={process.env.REACT_APP_TINYMCE_API_KET}
                  // cloudChannel="5-testing"
                />
              </div>
            </div>
          </>
        )}
        <div className="btn-wrap">
          <button
            type="button"
            className="btn btn-lg btn-black type2"
            onClick={() => {
              callback();
              closeModal();
            }}
          >
            동의하기
          </button>
        </div>
      </div>
    </div>
  );
};

export default PolicyModal;
