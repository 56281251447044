import React, { useEffect, useState } from "react";
import { getSolutionDetail } from "services/solution";
import { useParams, useNavigate } from "react-router-dom";
import { Editor } from "@tinymce/tinymce-react";
import useModal from "hooks/useModal";
import { userSolutionPurchaseRequest } from "services/user";
import useAuth from "hooks/useAuth";
import useViewCheck from "hooks/useViewCheck";

const SolutionStoreDetail = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const { isMobile } = useViewCheck();
  const { openModal, closeModal } = useModal();
  const { isLoggedin } = useAuth();

  const [data, setData] = useState(null);

  useEffect(() => {
    if (!Number.isInteger(Number(id))) {
      navigate("/solutionstore");
      return;
    }

    getSolutionDetail(id, (res) => {
      setData(res.response);
    });
  }, [id]);

  return (
    <div className="container">
      <div className="section" style={{ paddingBottom: 50 }}>
        <button
          type="button"
          className={`btn top-back`}
          style={isMobile ? { marginBottom: 0 } : {}}
          onClick={() => navigate("/solutionstore")}
        >
          솔루션 스토어 돌아가기
        </button>
        {data && (
          <div className="store-detail">
            <div className="left">
              <div className="image-wrap">
                <img src={data.view.BANNER} alt="" />
              </div>
              <div
                className="store-list--item"
                style={isMobile ? { marginTop: 0, marginBottom: 10 } : {}}
              >
                <div className="txt-wrap">
                  {Boolean(data.view.NEW) ? (
                    <div
                      className="top"
                      style={
                        isMobile ? { marginTop: 20, marginBottom: 24 } : {}
                      }
                    >
                      <span
                        className="badge badge-md badge-round black type2"
                        style={
                          isMobile
                            ? {
                                fontSize: "1.4rem",
                                padding: "0 14px",
                                height: 26,
                                fontWeight: 400,
                                lineHeight: "26px",
                              }
                            : {}
                        }
                      >
                        NEW
                      </span>
                    </div>
                  ) : (
                    <div style={isMobile ? { marginTop: 20 } : {}}></div>
                  )}
                  <div className="name">{data.view.TEAM_NAME}</div>
                  <div className="tit">{data.view.TITLE}</div>
                  {/* <div className="rate">
										<button type="button" className="btn btn-star active" readonly=""></button>
										<button type="button" className="btn btn-star active" readonly=""></button>
										<button type="button" className="btn btn-star active" readonly=""></button>
										<button type="button" className="btn btn-star active" readonly=""></button>
										<button type="button" className="btn btn-star half" readonly=""></button>
										<span className="count">(4.1)</span>
									</div> */}
                </div>
              </div>
              <div className="profile-badges">
                <div className="row">
                  <label className="label">TASK</label>
                  <div className="badges">
                    {data.view.AI_SKILL.VIDEO && (
                      <span className="badge badge-md primary2">동영상</span>
                    )}
                    {data.view.AI_SKILL.IMAGE && (
                      <span className="badge badge-md primary2">이미지</span>
                    )}
                    {data.view.AI_SKILL.SOUND && (
                      <span className="badge badge-md primary2">음성</span>
                    )}
                    {data.view.AI_SKILL.TEXT && (
                      <span className="badge badge-md primary2">텍스트</span>
                    )}
                    {data.view.AI_SKILL.BIG_DATA && (
                      <span className="badge badge-md primary2">빅데이터</span>
                    )}
                    {data.view.AI_SKILL.FUSION && (
                      <span className="badge badge-md primary2">융합</span>
                    )}
                    {data.view.AI_SKILL.SKILL_ETC && (
                      <span className="badge badge-md primary2">기타</span>
                    )}
                  </div>
                </div>
                <div className="row">
                  <label className="label">개발 형태</label>
                  <div className="badges">
                    {data.view.API && (
                      <span className="badge badge-md purple">API</span>
                    )}
                    {data.view.SDK && (
                      <span className="badge badge-md purple">SDK</span>
                    )}
                    {data.view.ON_PREMISE && (
                      <span className="badge badge-md purple">On-premise</span>
                    )}
                    {data.view.SI && (
                      <span className="badge badge-md purple">SI</span>
                    )}
                    {data.view.SAAS && (
                      <span className="badge badge-md purple">SaaS</span>
                    )}
                  </div>
                </div>
                <div className="row">
                  <label className="label">산업 분야</label>
                  <div className="badges">
                    {data.view.AI_INDUSTRY.EDUCATION && (
                      <span className="badge badge-md grey">교육</span>
                    )}
                    {data.view.AI_INDUSTRY.SERVICE && (
                      <span className="badge badge-md grey">서비스</span>
                    )}
                    {data.view.AI_INDUSTRY.MANUFACTURING && (
                      <span className="badge badge-md grey">
                        제조, 화학, 에너지
                      </span>
                    )}
                    {data.view.AI_INDUSTRY.VEHICLE && (
                      <span className="badge badge-md grey">자동차</span>
                    )}
                    {data.view.AI_INDUSTRY.SALES && (
                      <span className="badge badge-md grey">판매, 유통</span>
                    )}
                    {data.view.AI_INDUSTRY.MEDIA && (
                      <span className="badge badge-md grey">
                        미디어, 엔터테인먼트
                      </span>
                    )}
                    {data.view.AI_INDUSTRY.TOUR && (
                      <span className="badge badge-md grey">
                        관광, 스포츠, 레저
                      </span>
                    )}
                    {data.view.AI_INDUSTRY.CONSTRUCT && (
                      <span className="badge badge-md grey">
                        부동산, 건설, 건축
                      </span>
                    )}
                    {data.view.AI_INDUSTRY.FINANCE && (
                      <span className="badge badge-md grey">금융</span>
                    )}
                    {data.view.AI_INDUSTRY.MEDICAL && (
                      <span className="badge badge-md grey">
                        의료, 제약, 바이오, 헬스케어
                      </span>
                    )}
                    {data.view.AI_INDUSTRY.IT && (
                      <span className="badge badge-md grey">IT, 통신</span>
                    )}
                    {data.view.AI_INDUSTRY.GOVERNMENT && (
                      <span className="badge badge-md grey">정부, 기관</span>
                    )}
                    {data.view.AI_INDUSTRY.FOOD && (
                      <span className="badge badge-md grey">식품</span>
                    )}
                    {data.view.AI_INDUSTRY.AGRICULTURE && (
                      <span className="badge badge-md grey">농업</span>
                    )}
                    {data.view.AI_INDUSTRY.GAME && (
                      <span className="badge badge-md grey">게임</span>
                    )}
                    {data.view.AI_INDUSTRY.ETC && (
                      <span className="badge badge-md grey">기타</span>
                    )}
                  </div>
                </div>
              </div>
              <div
                className="profile-sub"
                style={isMobile ? { marginTop: 24 } : {}}
              >
                솔루션 소개
              </div>
              <div className="store-detail--box">
                <div className="edit-box web-basic">
                  <Editor
                    disabled={true}
                    toolbar={false}
                    // menubar={false}
                    inline={true}
                    initialValue={data.view.CONTENTS}
                    init={{
                      height: 500,
                      plugins: [
                        "advlist", "autolink", "lists", "link", "image", "charmap", "preview", "anchor",
                        "searchreplace", "visualblocks", "code" ,"fullscreen",
                        "insertdatetime", "media", "table", "code", "help", "wordcount",
                      ],
                      // toolbar: 'undo redo | blocks fontfamily fontsize | bold italic underline strikethrough | link image media table | align lineheight | numlist bullist indent outdent | emoticons charmap | removeformat',
                      automatic_uploads: true,
                      file_browser_callback_types: "image",
                      file_picker_callback: function (callback, value, meta) {
                        if (meta.filetype === "image") {
                          let input = document.getElementById("my-file");
                          if (!input) return;
                          input.click();
                          input.onchange = function () {
                            let file = input?.files[0];
                            let reader = new FileReader();
                            reader.onload = function (e) {
                              // console.log("name", (e.target).result);
                              callback(e.target.result, {
                                alt: file.name,
                              });
                            };
                            reader.readAsDataURL(file);
                          };
                        }
                      },
                    }}
                    // apiKey={process.env.REACT_APP_TINYMCE_API_KET}
                    // cloudChannel="5-testing"
                  />
                </div>
              </div>
            </div>
            {!isMobile && (
              <div className="right">
                <div className="right-box">
                  <button
                    type="button"
                    className="btn btn-md btn-primary"
                    onClick={() => {
                      if (isLoggedin) {
                        openModal({
                          type: "confirmModal",
                          contents: {
                            title: "구매 문의하기",
                            message:
                              "구매 문의를 선택하셨어요.\n문의를 진행하시겠어요?",
                            confirmText: "문의하기",
                            cancelText: "다시 확인하기",
                            cancelCallback: () => {
                              closeModal();
                            },
                            confirmCallback: () => {
                              userSolutionPurchaseRequest(id, (res) => {
                                if (res.code === 0) {
                                  openModal({
                                    type: "messageModal",
                                    contents: {
                                      title: "문의 완료",
                                      message:
                                        "구매 문의가 완료되었어요.\n담당 매니저를 통해 곧 연락 드릴게요.",
                                      confirmText: "확인",
                                      confirmCallback: () => {
                                        closeModal();
                                        //이중호출로 기존 모달도 닫기
                                        closeModal();
                                      },
                                    },
                                  });
                                } else {
                                  openModal({
                                    type: "messageModal",
                                    contents: {
                                      title: "요청에 실패했습니다",
                                      message:
                                        "예기치 못한 오류가 발생했습니다. 나중에 다시 시도해 주세요",
                                      confirmText: "확인",
                                      confirmCallback: () => {
                                        closeModal();
                                      },
                                    },
                                  });
                                }
                              });
                            },
                          },
                        });
                      } else {
                        openModal({
                          type: "loginModal",
                          contents: {
                            message:
                              "구매 문의하기는 로그인 후 이용할 수 있어요.",
                          },
                        });
                      }
                    }}
                  >
                    구매 문의하기
                  </button>
                  {(data.recommend || Boolean(data.recommend.length)) && (
                    <>
                      <div className="box-tit">
                        함께 알아보면 좋을{" "}
                        <span className="txt-c--purple">솔루션</span>
                      </div>
                      <ul className="thumb-list type2">
                        {data.recommend?.map((item) => (
                          <li
                            className="thumb-list--item"
                            key={`thumb-list--item-${item.SEQ}`}
                            onClick={() => {
                              navigate(`/solutionstore/${item.SEQ}`);
                            }}
                          >
                            <div className="image-wrap" style={{ height: 76 }}>
                              <img src={item.BANNER} alt="" />
                            </div>
                            <div className="text-wrap">
                              <div className="top">
                                {item.TEAM_NAME}
                                <span className="badge badge-sm primary">
                                  기업
                                </span>
                              </div>
                              <div className="tit">{item.TITLE}</div>
                              {/* <div className="rate">
														<button type="button" className="btn btn-star active" readonly=""></button>
														<button type="button" className="btn btn-star active" readonly=""></button>
														<button type="button" className="btn btn-star active" readonly=""></button>
														<button type="button" className="btn btn-star active" readonly=""></button>
														<button type="button" className="btn btn-star half" readonly=""></button>
														<span className="count">4.1</span>
													</div> */}
                              <div className="badges">
                                {item.VIDEO && (
                                  <span className="badge badge-md primary2">
                                    동영상
                                  </span>
                                )}
                                {item.IMAGE && (
                                  <span className="badge badge-md primary2">
                                    이미지
                                  </span>
                                )}
                                {item.SOUND && (
                                  <span className="badge badge-md primary2">
                                    음성
                                  </span>
                                )}
                                {item.TEXT && (
                                  <span className="badge badge-md primary2">
                                    텍스트
                                  </span>
                                )}
                                {item.BIG_DATA && (
                                  <span className="badge badge-md primary2">
                                    빅데이터
                                  </span>
                                )}
                                {item.FUSION && (
                                  <span className="badge badge-md primary2">
                                    융합
                                  </span>
                                )}
                                {item.SKILL_ETC && (
                                  <span className="badge badge-md primary2">
                                    기타
                                  </span>
                                )}
                              </div>
                            </div>
                          </li>
                        ))}
                      </ul>
                    </>
                  )}
                </div>
              </div>
            )}
            {isMobile && (data.recommend || Boolean(data.recommend.length)) && (
              <div className="right">
                <div className="right-box">
                  <div className="box-tit">
                    함께 알아보면 좋을{" "}
                    <span className="txt-c--purple">솔루션</span>
                  </div>
                  <ul className="thumb-list type2">
                    {data.recommend?.map((item) => (
                      <li
                        className="thumb-list--item"
                        key={`thumb-list--item-${item.SEQ}`}
                        onClick={() => {
                          navigate(`/solutionstore/${item.SEQ}`);
                        }}
                      >
                        <div className="image-wrap" style={{ height: 76 }}>
                          <img src={item.BANNER} alt="" />
                        </div>
                        <div className="text-wrap">
                          <div className="top">
                            {item.TEAM_NAME}
                            <span className="badge badge-sm primary">기업</span>
                          </div>
                          <div className="tit">{item.TITLE}</div>
                          {/* <div className="rate">
														<button type="button" className="btn btn-star active" readonly=""></button>
														<button type="button" className="btn btn-star active" readonly=""></button>
														<button type="button" className="btn btn-star active" readonly=""></button>
														<button type="button" className="btn btn-star active" readonly=""></button>
														<button type="button" className="btn btn-star half" readonly=""></button>
														<span className="count">4.1</span>
													</div> */}
                          <div className="badges">
                            {item.VIDEO && (
                              <span className="badge badge-md primary2">
                                동영상
                              </span>
                            )}
                            {item.IMAGE && (
                              <span className="badge badge-md primary2">
                                이미지
                              </span>
                            )}
                            {item.SOUND && (
                              <span className="badge badge-md primary2">
                                음성
                              </span>
                            )}
                            {item.TEXT && (
                              <span className="badge badge-md primary2">
                                텍스트
                              </span>
                            )}
                            {item.BIG_DATA && (
                              <span className="badge badge-md primary2">
                                빅데이터
                              </span>
                            )}
                            {item.FUSION && (
                              <span className="badge badge-md primary2">
                                융합
                              </span>
                            )}
                            {item.SKILL_ETC && (
                              <span className="badge badge-md primary2">
                                기타
                              </span>
                            )}
                          </div>
                        </div>
                      </li>
                    ))}
                  </ul>
                </div>
              </div>
            )}
          </div>
        )}
      </div>

      {isMobile && (
        <div className="m-flex m-floating">
          <button
            type="button"
            style={{ width: "100%", height: "50px" }}
            className="btn btn-md btn-primary send mt-0"
            onClick={() => {
              if (isLoggedin) {
                openModal({
                  type: "confirmModal",
                  contents: {
                    title: "구매 문의하기",
                    message:
                      "구매 문의를 선택하셨어요.\n문의를 진행하시겠어요?",
                    confirmText: "문의하기",
                    cancelText: "다시 확인하기",
                    cancelCallback: () => {
                      closeModal();
                    },
                    confirmCallback: () => {
                      userSolutionPurchaseRequest(id, (res) => {
                        if (res.code === 0) {
                          openModal({
                            type: "messageModal",
                            contents: {
                              title: "문의 완료",
                              message:
                                "구매 문의가 완료되었어요.\n담당 매니저를 통해 곧 연락 드릴게요.",
                              confirmText: "확인",
                              confirmCallback: () => {
                                closeModal();
                              },
                            },
                          });
                        } else {
                          openModal({
                            type: "messageModal",
                            contents: {
                              title: "요청에 실패했습니다",
                              message:
                                "예기치 못한 오류가 발생했습니다. 나중에 다시 시도해 주세요",
                              confirmText: "확인",
                              confirmCallback: () => {
                                closeModal();
                              },
                            },
                          });
                        }
                      });
                    },
                  },
                });
              } else {
                openModal({
                  type: "loginModal",
                  contents: {
                    message: "구매 문의하기는 로그인 후 이용할 수 있어요.",
                  },
                });
              }
            }}
          >
            구매 문의하기
          </button>
        </div>
      )}
    </div>
  );
};

export default SolutionStoreDetail;
