import { Editor } from "@tinymce/tinymce-react";
import useViewCheck from "hooks/useViewCheck";
import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { getFAQDetail } from "services/user";
import { dateFormatType2NoTime } from "utils/utils";

const FAQDetail = () => {
  const navigate = useNavigate();
  const { isMobile } = useViewCheck();
  const { id } = useParams();
  const [data, setData] = useState({});
  const [dataPrev, setDataPrev] = useState(null);
  const [dataNext, setDataNext] = useState(null);

  useEffect(() => {
    if (!Number.isInteger(Number(id))) {
      navigate("/help/faq");
      return;
    }

    getFAQDetail(id, (res) => {
      setData(res.response.now);
      setDataPrev(res.response?.prev);
      setDataNext(res.response?.next);
    });
  }, [id]);

  const getCategoryName = (category) => {
    switch (category) {
      case "CLIENT":
        return {
          name: "클라이언트",
          color: "lime",
        };
      case "HEROES":
        return {
          name: "파트너",
          color: "primary2",
        };
      case "PROJECT":
        return {
          name: "프로젝트",
          color: "purple2",
        };
      case "MEETING":
        return {
          name: "미팅",
          color: "blue",
        };
      case "CONTRACT":
        return {
          name: "계약",
          color: "primary",
        };
      case "FEE":
        return {
          name: "요금",
          color: "pink",
        };
      default:
        return {};
    }
  };

  return (
    <div className="container">
      <button
        type="button"
        className="btn top-back"
        onClick={() => navigate("/help/faq")}
      >
        리스트로 돌아가기
      </button>
      <div className="board-detail" style={isMobile ? { marginTop: 0 } : {}}>
        <div className="top">
          <span
            className={`badge badge-md ${getCategoryName(data.CATEGORY).color}`}
          >
            {getCategoryName(data.CATEGORY).name}
          </span>
          {dateFormatType2NoTime(data.CREATED_AT)}
        </div>
        <div className="tit">{data.TITLE}</div>
        <div className="con">
          <div
            className="edit-box web-basic"
            style={{
              marginTop: 0,
            }}
          >
            <Editor
              disabled={true}
              toolbar={false}
              // menubar={false}
              inline={true}
              initialValue={data.CONTENTS}
              init={{
                height: 500,
                plugins: [
                  "advlist", "autolink", "lists", "link", "image", "charmap", "preview", "anchor",
                  "searchreplace", "visualblocks", "code" ,"fullscreen",
                  "insertdatetime", "media", "table", "code", "help", "wordcount",
                ],
                // toolbar: 'undo redo | blocks fontfamily fontsize | bold italic underline strikethrough | link image media table | align lineheight | numlist bullist indent outdent | emoticons charmap | removeformat',
                automatic_uploads: true,
                file_browser_callback_types: "image",
                file_picker_callback: function (callback, value, meta) {
                  if (meta.filetype === "image") {
                    let input = document.getElementById("my-file");
                    if (!input) return;
                    input.click();
                    input.onchange = function () {
                      let file = input?.files[0];
                      let reader = new FileReader();
                      reader.onload = function (e) {
                        // console.log("name", (e.target).result);
                        callback(e.target.result, {
                          alt: file.name,
                        });
                      };
                      reader.readAsDataURL(file);
                    };
                  }
                },
              }}
              // apiKey={process.env.REACT_APP_TINYMCE_API_KET}
              // cloudChannel="5-testing"
            />
          </div>
        </div>
      </div>
      <div className="board-bottom">
        {dataPrev && (
          <div
            className="prev"
            onClick={() => {
              navigate(`/help/faq/${dataPrev.SEQ}`);
            }}
          >
            <button type="button" className="btn prev">
              이전
            </button>
            <div className="board-list--item">
              <div className="badge-wrap">
                <span
                  className={`badge badge-md ${
                    getCategoryName(dataPrev.CATEGORY).color
                  }`}
                >
                  {getCategoryName(dataPrev.CATEGORY).name}
                </span>
              </div>
              <button type="button" className="btn tit">
                {dataPrev.TITLE}
              </button>
              <span className="date">
                {dateFormatType2NoTime(dataPrev.CREATED_AT)}
              </span>
            </div>
          </div>
        )}
        {dataNext && (
          <div
            className="next"
            onClick={() => {
              navigate(`/help/faq/${dataNext.SEQ}`);
            }}
          >
            <button type="button" className="btn next">
              다음
            </button>
            <div className="board-list--item">
              <div className="badge-wrap">
                <span
                  className={`badge badge-md ${
                    getCategoryName(dataNext.CATEGORY).color
                  }`}
                >
                  {getCategoryName(dataNext.CATEGORY).name}
                </span>
              </div>
              <button type="button" className="btn tit">
                {dataNext.TITLE}
              </button>
              <span className="date">
                {dateFormatType2NoTime(dataNext.CREATED_AT)}
              </span>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default FAQDetail;
