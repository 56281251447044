import React, { useState, useEffect } from 'react'
import { termsDetailView, termsListView } from 'services/home';
import { Editor } from '@tinymce/tinymce-react';

const Service = () => {
	const [contents, setContents] = useState(null);

	useEffect(() => {
		termsListView((res) => {
			const useList = res.response.filter((a) => a.CATEGORY === 'USE');

			const useTermsTemp = useList.reduce((max, p) => {
				const applyAt = Number(p.APPLY_AT.split('-').join(''))
				const maxApplyAt = Number(max.APPLY_AT.split('-').join(''))
				return applyAt > maxApplyAt ? p : max;
			}, useList[0]);

			termsDetailView(useTermsTemp.SEQ, (res) => {
				// console.log(res.response)
				setContents(res.response)
			})
		})
	}, [])


	// 	const contents = `
	// 	제1장 총칙

	// 	제1조(목적)
	// 본 약관은 주식회사 비바리퍼블리카(이하 "회사"라 함)가 제공하는 전자금융거래 서비스 및 관련 서비스(이하 "서비스"라 함)의 이용과 관련하여, 회사와
	// 회원 간 또는 회원 간의 권리 및 의무 및 책임사항 및 서비스 이용조건 및 절차 등 기본적인 사항을 규정함을 목적으로 합니다.

	// 		제2조(용어의 정의)
	// ① 본 약관에서 사용하는 용어의 정의는 다음과 같습니다.
	// 1. "회원"이란 본 약관에 따라 가입신청에 대한 회사의 승낙을 받아 회사가 제공하는 서비스를 이용하는 자를 말합니다.
	// 2. "토스앱"이란 컴퓨터 등의 정보통신설비 또는 스마트폰, 태블릿 등의 모바일 정보통신설비를 이용하여 회사가 서비스를 제공할 수 있도록 설정한 가상의
	// 영업장 또는 서비스 공간을 말하며, 애플리케이션, 웹페이지 등을 포함합니다.
	// 3. "접근매체"란 전자금융거래에 있어서 거래지시를 하거나 이용자 및 거래내용의 진실성과 정확성을 확보하기 위하여 사용되는 전자금융거래법 제2조
	// 제10호에서 정하는 수단 또는 정보로서, 전자금융거래서비스를 이용하기 위하여 회사에 등록된 암호, 금융회사 또는 전자금융업자에 등록된 회원번호 등 회사
	// 가 지정한 수단을 말합니다.
	// 4. "PIN"이란 회원이 서비스의 이용과 관련하여, 회원 본인 확인 및 식별, 거래 승인을 위하여 회원이 직접 설정하고 토스앱에 직접 입력하는 숫자와 문자의
	// 조합 또는 그에 상응하는 수단을 말합니다.
	// 5. "전자금융거래"란 회사가 전자적 장치를 통하여 금융상품 및 서비스를 제공(이하 "전자금융업무"라 함)하고, 이용자가 회사의 종사자와 직접 대면하거나
	// 의사소통을 하지 아니하고 자동화된 방식으로 이를 이용하는 거래를 말합니다.
	// 6. "전자지급수단"이란 선불전자지급수단 등 전자금융거래법 제2조 제11호에서 정하는 전자적 방법에 따른 지급수단을 말합니다.
	// 7. "전자지급거래"란 자금을 주는 자가 회사로 하여금 전자지급수단을 이용하여 자금을 받는 자에게 자금을 이동하게 하는 전자금융거래를 말합니다.
	// 8. "거래지시"란 회원이 본 약관에 따라 회사에게 전자금융업무의 처리를 지시하는 것을 말합니다.
	// 9. "전자적 장치"란 전자금융거래정보를 전자적 방법으로 전송하거나 처리하는 데 이용되는 장치로서 현금자동지급기, 자동입출금기, 지급용 단말기, 컴퓨터,
	// 	휴대전화, 태블릿 등 휴대용 단말기, 그 밖에 전자적 방법으로 정보를 전송하거나 처리하는 장치를 말합니다.
	// 10. "선불전자지급수단" 또는 “선불”이란 회원이 회사로부터 발급받은 회원 계정에 부여된 전자금융거래법상 선불전자지급수단을 말합니다.
	// 11. “토스머니”란 선불전자지급수단 중 회원이 회사에 직접 대가를 지급하고 구매하여 회원이 완전한 소유권, 처분권 등을 보유한 것을 말합니다.
	// 12. "토스포인트"란 선불전자지급수단 중 “토스머니” 이외의 것을 말합니다.
	// 13. "충전"이란 회원이 서비스를 이용하기 위하여 회사가 회원의 토스머니와 연결된 금융회사의 계좌에서 회원이 요청한 금액을 출금하고 해당 금액만큼
	// 회원의 토스머니를 증액하거나, 다른 회원의 요청에 따라 해당 회원이 보유하고 있는 토스머니를 감액하고 감액된 금액만큼 회원의 토스머니를 증액하는 등의
	//     방법으로 회원의 토스머니 보유 잔액을 증가시키는 행위를 말합니다.
	// 14. "환급"이란 회사가 회원의 요청에 따라 회원의 토스머니의 잔액 범위 내에서 회원이 요청하는 금액을 회원이 지정한 금융회사의 계좌로 입금하는 등 회사
	// 가 정한 방법으로 지급하는 것을 말합니다.
	// 15. "수수료"란 회원이 회사의 서비스를 이용하는 과정에서 회사가 정하는 기준에 따라 회원에게 부과하는 비용을 말합니다.
	// 16. "가맹점"이란 회사와 가맹점 계약을 체결하여 서비스를 이용한 재화나 용역에 관한 지급 및 결제, 선불전자지급수단의 적립 등을 할 수 있는 금융회사 또는
	//     전자금융업자가 아닌 자를 말합니다.
	// 17. "결제대금예치서비스"란 회원이 가맹점과의 상품 또는 용역의 거래를 함에 있어, 회사가 그 거래에 관한 결제 대금의 전부나 일부를 예치 받아 보관하다가,
	// 	해당 거래가 완료된 후 해당 결제 대금을 가맹점에게 지급하는 서비스를 말합니다.
	// 18. "직불전자지급수단"이란 회원과 가맹점 간에 전자적 방법에 따라 금융회사의 계좌에서 자금을 이체하는 등의 방법으로 재화 또는 용역의 제공과 그 대가의
	//     지급을 동시에 이행할 수 있도록 회사가 발행한 증표 또는 그 증표에 관한 정보를 말합니다.
	// 19. “전자지급결제대행서비스”란 전자적 방법으로 재화 또는 용역의 구매에 있어서 지급결제정보를 송신하거나 수신하는 것 또는 그 대가의 정산을
	// 대행하거나 매개하는 서비스를 말합니다.
	// 20. “전자고지결제서비스”란 전화료, 신용카드 요금, 아파트관리비, 세금, 보험료 등 각종 대금 청구서를 직접 또는 제3자를 통해 전자적 방식으로
	// 발행·고지하고 회원이 이를 확인한 후 해당 대금을 납부할 수 있도록 회사가 회원에게 제공하는 서비스를 말합니다.
	// 21. “카드모집서비스”란 신용카드회사와 여신전문금융업법 제14조의2 제1항 제3호에서 정한 업무제휴계약을 체결하는 등의 방법으로 신용카드회사가
	// 회사에 위탁한 범위 내의 신용카드 관련 서비스를 토스앱에서 이용할 수 있도록 회사가 회원에게 제공하는 서비스를 말합니다.
	// 22. “위탁금융사”란 회사에 카드모집서비스 업무를 위탁한 신용카드회사를 말합니다.
	// 23. “모집서비스 대상 상품”이란 위탁금융사가 운영하는 금융상품 중 회사에게 업무를 위탁한 개별 상품을 말합니다.
	// 24. “게시물”이라 함은 회원이 서비스를 이용함에 있어 서비스상에 게시한 부호, 문자, 음성, 음향, 화상, 동영상 등의 정보 형태의 글, 사진, 동영상, 각종 파일
	//     또는 링크 등을 말합니다.
	// ② 본 조 및 본 약관의 다른 조항에서 정의한 것을 제외하고는 전자금융거래법 등 관련 법령에 정한 바에 따릅니다.

	// 	제3조(이용약관의 효력 및 약관변경 승인)
	// `

	return (
		<div className="container">
			<div className="terms">
				<div className="terms-tit">
					서비스 이용약관
					<span className="date">{contents?.APPLY_AT}</span>
				</div>
				<div className="terms-con">
					<div className="edit-box web-basic" style={{ marginTop: 0 }}>
						<Editor
							disabled={true}
							toolbar={false}
							// menubar={false}
							inline={true}
							initialValue={contents?.DETAILS}

							init={{
								height: 500,
								plugins: [
									"advlist", "autolink", "lists", "link", "image", "charmap", "preview", "anchor",
									"searchreplace", "visualblocks", "code" ,"fullscreen",
									"insertdatetime", "media", "table", "code", "help", "wordcount",
								],
								// toolbar: 'undo redo | blocks fontfamily fontsize | bold italic underline strikethrough | link image media table | align lineheight | numlist bullist indent outdent | emoticons charmap | removeformat',
								automatic_uploads: true,
								file_browser_callback_types: "image",
								file_picker_callback: function (callback, value, meta) {
									if (meta.filetype === "image") {
										let input = document.getElementById(
											"my-file"
										);
										if (!input) return;
										input.click();
										input.onchange = function () {
											let file = input?.files[0];
											let reader = new FileReader();
											reader.onload = function (e) {
												// console.log("name", (e.target).result);
												callback((e.target).result, {
													alt: file.name,
												});
											};
											reader.readAsDataURL(file);
										};
									}
								},
							}}
							// apiKey={process.env.REACT_APP_TINYMCE_API_KET}
							// cloudChannel='5-testing'
						/>
					</div>
				</div>
				{/* <button type="button" className="btn terms-link">변경 전 이용약관 보기</button> */}
			</div>
		</div>
	)
}

export default Service